import * as React from "react";
import ReactDOM, { unmountComponentAtNode } from "react-dom";

import { Button } from "view/common/button";
import ExtLink from "view/components/external-link";

import { isSupportedBrowser, isMobileDevice } from "util/supported-device";

import GRAY_LOGO from "assets/images/gray-logo.svg";
import SUPPORTED_BROWSERS from "assets/images/supported-browsers.svg";

import "./block-browser.scss";

type Props = {
    image: string;
    title: React.ReactNode;
    description: React.ReactNode;
    actions?: React.ReactNode;
};

const UnsupportedBrowserPage = ({
    image,
    title,
    description,
    actions,
}: Props) => {
    let actionsNode;
    if (actions) {
        actionsNode = <div className="actions">{actions}</div>;
    }

    return (
        <div className="unsupported-browser-page">
            <img alt="MemSQL Studio" src={GRAY_LOGO} />

            <div className="content">
                <img alt="MemSQL Studio" src={image} />

                <div className="title">{title}</div>

                <div className="description">{description}</div>

                {actionsNode}
            </div>
        </div>
    );
};

const reactRoot = document.getElementById("react-root");

if (reactRoot) {
    // We clear up what is in #react-root as early as we can to avoid users from
    // seeing the "Enable JavaScript" message in it while React is loading.
    unmountComponentAtNode(reactRoot);

    let page;

    if (isMobileDevice()) {
        page = (
            <UnsupportedBrowserPage
                image={SUPPORTED_BROWSERS}
                title="We only support desktop devices"
                description={
                    <>
                        To get the most out of MemSQL Studio, please use a
                        laptop or desktop computer.
                    </>
                }
            />
        );
    } else if (!isSupportedBrowser()) {
        page = (
            <UnsupportedBrowserPage
                image={SUPPORTED_BROWSERS}
                title="We only support the latest Chrome and Firefox"
                description={
                    <>
                        To get the most out of MemSQL Studio, please use the
                        most up to date version of{" "}
                        <ExtLink name="chrome" category="block-browser">
                            Chrome
                        </ExtLink>{" "}
                        or{" "}
                        <ExtLink name="firefox" category="block-browser">
                            Firefox
                        </ExtLink>
                        .
                    </>
                }
                actions={
                    <>
                        <Button
                            large
                            primary
                            externalLink="chrome"
                            category="block-browser"
                        >
                            Get Chrome (Recommended)
                        </Button>
                        <Button
                            large
                            externalLink="firefox"
                            category="block-browser"
                        >
                            Get Firefox
                        </Button>
                    </>
                }
            />
        );
    }

    if (page) {
        ReactDOM.render(page, reactRoot);
    }
}
